<template>
  <div style="
      background-color: rgba(33, 34, 68, 1);
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    ">
    <van-row>
      <van-col span="24"
               style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 0.8125rem;
        ">
        {{ $t("tongji") }} {{ token_name }}
      </van-col>
      <van-col span="24"
               style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 1.875rem;
        ">
        CUSDT {{ addyue.balance }}
        <span style="font-size: 1.875rem; color: rgba(112, 128, 179, 1)">{{
          token_name
        }}</span>
      </van-col>
    </van-row>
  </div>
  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <van-row>
      <van-col span="24"
               style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
        ">
        <van-row>
          <van-col span="12">
            {{ $t("address") }}
          </van-col>
          <van-col span="12"
                   style="text-align: right">
            {{
              address.substring(0, 4) +
              "****" +
              address.substring(address.length - 5)
            }}
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24"
               style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="24"
                   style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="12">
                {{ $t("duihuanbili") }}
              </van-col>
              <van-col span="12"
                       style="text-align: right">
                1:1
                <span style="color: rgba(112, 128, 179, 1)">CUSDT</span>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="24"
                   style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="12">
                {{ $t("keduihuan") }}
              </van-col>
              <van-col span="12"
                       style="text-align: right">
                {{ addyue.leiji }}
                <span style="color: rgba(112, 128, 179, 1)">CUSDT</span>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="24"
                   style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="24">
                {{ $t("duihuanshuliang") }}
              </van-col>
              <van-col span="24"
                       style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number"
                           style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      "
                           placeholder="0.00"
                           v-model="change_price" />
                  </van-col>
                  <van-col span="16"
                           style="text-align: right">
                    <div style="
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                      ">
                      <span style="margin-left: 5px">{{ token_name }}</span>
                      <van-button color="#f90"
                                  size="mini"
                                  round
                                  style="margin-left: 5px">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24"
               style="margin-top: 20px">
        <van-button color="rgba(107,84,238,1)"
                    size="large"
                    @click="duihuan">{{
          $t("lijiduihuan")
        }}</van-button>
      </van-col>
      <!-- <van-col span="24" style="margin-top: 20px">
        <van-button color="#363762" size="large">{{
          $t("duihuanjilu")
        }}</van-button>
      </van-col> -->
    </van-row>
  </div>
  <div style="width: 100%; padding: 30px; box-sizing: border-box; border-radius: 20px; background-color: #212244; margin-top: 20px">
    <van-row>
      <van-col span="24"
               style="font-size: 1.5rem">
        {{ $t("fenjishouru") }}
      </van-col>
      <van-col span="24"
               style="margin-top: 20px;color: #7080b3">
        <van-row>
          <van-col span="8">
            {{ $t("dengji") }}
          </van-col>
          <van-col span="8"
                   style="text-align: center">
            {{ $t("zhouqi") }}
          </van-col>
          <van-col span="8"
                   style="text-align: right">
            {{ $t("shouyi") }}(%)
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px"
                 v-for="(item, index) in levelList"
                 :key="index">
          <van-col span="8">
            {{ item.level_name }}
          </van-col>
          <van-col span="8"
                   style="text-align: center">
            {{ item.min_balance }}
          </van-col>
          <van-col span="8"
                   style="text-align: right">
            {{ item.min_income }}%
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div style="margin-top: 20px; background-color: #212244; padding: 30px">
    <van-row>
      <van-col span="24">
        {{ $t("changjianwenti") }}
      </van-col>
      <van-col span="24"
               style="margin-top: 20px">
        <van-row gutter="20">
          <van-col span="4">
            <img :src="require('@/assets/image/info1.png')"
                 alt=""
                 style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info2.png')"
                 alt=""
                 style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info3.png')"
                 alt=""
                 style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info4.png')"
                 alt=""
                 style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info5.png')"
                 alt=""
                 style="height: 32px; width: 32px" />
          </van-col>
          <van-col span="4">
            <img :src="require('@/assets/image/info6.png')"
                 alt=""
                 style="height: 32px; width: 32px" />
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "change",
  data () {
    return {
      levelList: [
        {
          level_name: "primary",
          min_balance: "30",
          min_income: "1.5"
        },
        {
          level_name: "intermediate",
          min_balance: "60day",
          min_income: "2"
        },
        {
          level_name: "senior",
          min_balance: "90day",
          min_income: "3"
        },
      ],
      change_price: 0.0,
      tronweb: "",
      address: "",
      balance: "",
      token_name: "",
      token_price: "",
      token_number: 0,
      usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      addyue: {},
    };
  },
  methods: {
    getLevel () {
      this.$request.post("index/getLevel", "").then(res => {
        if (res.data.code === 200) {
          this.levelList = res.data.data;
        }
      })
    },
    duihuan () {
      $.ajax({
        url: "https://admin.a506f.top/api/index/duihuan",
        type: "post",
        data: {
          address: this.address,
          money: this.change_price,
        },
        success: (res) => {
          this.$toast(res.msg);
          location.reload();
          //   console.log(res);
        },
      });
    },
  },
  mounted () {
    // 获取sessionStorage中的地址
    this.getLevel();
    if (
      sessionStorage.getItem("address") != "" &&
      sessionStorage.getItem("address") != "false"
    ) {
      this.address = sessionStorage.getItem("address");
      $.ajax({
        url: "https://admin.a506f.top/api/index/addyue",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          console.log(res);
          this.addyue = res;
        },
      });
    }
    // if (this.$route.params.invite_code) {
    //   this.parent = this.$route.params.invite_code;
    // }
    // let lian = sessionStorage.getItem("lian");
    // if (lian == "trc20") {
    //   this.ischain.chain = "trc20";
    //   this.ischain.url = require("@/assets/image/trc.png");
    // } else if (lian == "erc20") {
    //   this.ischain.chain = "erc20";
    //   this.ischain.url = require("@/assets/image/erc.png");
    // } else if (lian == "binance") {
    //   this.ischain.chain = "binance";
    //   this.ischain.url = require("@/assets/image/biance.png");
    // } else if (lian == "usdc") {
    //   this.ischain.chain = "usdc";
    //   this.ischain.url = require("@/assets/image/usdc.png");
    // } else {
    //   this.ischain.chain = "trc20";
    //   this.ischain.url = require("@/assets/image/trc.png");
    // }
  },
};
</script>

<style scoped></style>
